<template>
	<v-container fluid class="px-0 pt-0">
		<v-row no-gutters class="design-banner" justify="center">
			<v-col cols="10" sm="5" class="pl-4 pt-4">
				<h1 class="text-h5 text-sm-h4 font-weight-light mt-4 mr-auto mb-2">Sua Empresa Online!</h1>
				<h2 class="text-h6 text-sm-h5 font-weight-light mr-auto mb-1">Tenha uma <b>Agenda de Serviços</b> e uma <b>Loja Virtual</b> na internet.</h2>
				<div class="collored-spanner primary mb-6"></div>

				<v-btn depressed dark to="/criar-conta" color="orange darken-2" @click.native="$logEvent('cta_one_clicked')">
					<v-icon right class="ml-1 mr-3">rocket_launch</v-icon> comece grátis
				</v-btn>

				<div class="scroll-instructions mt-16 d-none d-sm-block">
					<p class="text-overline mb-2">role para baixo e saiba mais</p>
					<v-icon large class="scroll-ico mb-16">keyboard_double_arrow_down</v-icon>
				</div>
			</v-col>

			<v-col cols="10" sm="5" class="pr-4 pt-4 mt-4">
				<v-img width="100%" src="../assets/banner/apps.jpg" />
			</v-col>
		</v-row>

		<v-row no-gutters align="center" justify="center" class="my-8 my-sm-4">
			<v-col cols="12" class="text-overline text-center pt-3 mb-6" style="font-size: 1.2em !important;">diga adeus para os agendamentos manuais</v-col>
			<v-col cols="10" xsm="8" offset="1" xoffset-sm="2" class="text-subtitle-1 text-center mb-6">
				<v-list dense>
					<v-list-item v-for="(item, i) in gatilhos" :key="`gatilho-social-${i}`">
						<v-list-item-icon class="my-auto">
							<v-icon>{{item.icon}}</v-icon>
						</v-list-item-icon>

						<v-list-item-content class="text-left text-body-2">
							{{item.text}}
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-col>

			<v-col cols="10" class="text-overline text-center pt-3 mb-6" style="font-size: 1.2em !important;">veja o que nossos clientes dizem</v-col>
			<v-col cols="12" md="10" lg="8" class="py-8">
				<v-slide-group center-active show-arrows class="center-slider">
					<v-slide-item v-slot="{ toggle }" v-for="(zap, i) in zaps" :key="i">
						<div>
							<v-img @click="toggle" :src="zap" max-width="260" class="mx-4 d-sm-none" />
							<v-img @click="toggle" :src="zap" max-width="320" class="mx-4 d-none d-sm-block" />
						</div>
					</v-slide-item>
				</v-slide-group>
			</v-col>
		</v-row>

		<v-row no-gutters align="center" class="flex-column my-8 my-sm-4">
			<v-col cols="10" sm="8" md="6" class="text-overline text-center pt-3 mb-6" style="font-size: 1.2em !important;">conheça mais os benefícios</v-col>

			<v-col cols="12" sm="10" class="beneficios-wrapper py-8">
				<v-slide-group dark center-active show-arrows class="center-slider">
					<v-slide-item v-slot="{ toggle }" v-for="(step, i) in steps" :key="i">
						<v-card @click="toggle" width="280" height="110" rounded="lg" class="ma-2 white" elevation="0">
							<v-list-item two-line>
								<v-list-item-avatar rounded="lg" size="80" :color="step.color">
									<v-icon dark size="48" v-text="step.icon" />
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title class="black--text text-overline mb-0" v-text="step.title" />
									<v-list-item-subtitle class="black--text text-body-2 beneficios-text" v-html="step.text" />
								</v-list-item-content>
							</v-list-item>
						</v-card>
					</v-slide-item>
				</v-slide-group>
			</v-col>
		</v-row>

		<v-row no-gutters justify="center" class="app-banner my-16">
			<v-col cols="10" sm="6" class="my-4">
				<v-img class="mx-auto ml-sm-auto mr-sm-0" max-width="320" width="100%" src="../assets/banner/agenda.png" />
			</v-col>

			<v-col cols="10" sm="6" class="my-4 d-flex flex-column justify-space-between">
				<v-card v-for="(item, key) in features" :key="key" elevation="4" width="240" rounded="xl" class="feature-card mx-auto my-3" xcolor="transparent">
					<v-card-title class="text-center">
						<v-icon x-large class="mx-auto">{{item.icon}}</v-icon>
					</v-card-title>

					<v-card-text class="text-center body-1" v-html="item.text" />
				</v-card>
			</v-col>

			<v-col cols="10" align="center" class="mt-12">
				<v-btn depressed dark to="/criar-conta" color="orange darken-2" @click.native="$logEvent('cta_two_clicked')">
					<v-icon right class="mr-2">rocket_launch</v-icon>
					<span class="d-sm-none">comece grátis</span>
					<span class="d-none d-sm-block">começar meu teste grátis</span>
				</v-btn>
			</v-col>
		</v-row>

		<v-row no-gutters justify="center" class="mb-6">
			<v-col cols="10" class="text-overline text-center mt-0 mb-6 d-sm-none" style="font-size: 1.2em !important;">dúvidas frequentes</v-col>
			<v-col cols="10" class="text-overline text-center mt-0 mb-6 d-none d-sm-block" style="font-size: 1.2em !important;">veja as dúvidas mais frequentes</v-col>

			<v-col cols="10">
				<v-expansion-panels accordion>
					<v-expansion-panel v-for="(faq, key) in faqs" :key="key" @click="$logEvent('accordion_clicked')">
						<v-expansion-panel-header v-text="faq.question" class="text-subtitle-1" />
						<v-expansion-panel-content v-html="faq.answer" class="pa-6 text-body-2" />
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>
export default {
  	name: 'home',

  	data() {
		return {
			zaps: [require('../assets/banner/wpp-1.png'), require('../assets/banner/wpp-2.png'), require('../assets/banner/wpp-3.png')],
			gatilhos: [
				{icon: 'star', text: 'Organize suas agendas evitando erros e conflitos de horários'},
				{icon: 'star', text: 'Promove mais facilidade para seus clientes agendarem um horário'},
				{icon: 'star', text: 'Aumente a praticidade da sua agenda estando disponível 24 horas por dia 100% online'},
			],
			steps: [
				{ color: 'purple darken-3', 	icon: 'mobile_screen_share', 	title: 'página online', text: 'Seus clientes podem <b>agendar um horário</b> ou comprar algo pela sua página.' },
				{ color: 'red accent-4', 		icon: 'work', 					title: 'multi agendas', text: 'Cadastre todos os seus funcionários <b>sem pagar nada a mais por isso.</b>'},
				{ color: 'teal darken-2', 		icon: 'notifications_active', 	title: 'lembretes', 	text: 'Envio <b>automático</b> por SMS ou E-mail e envio manual por Whatsapp.' },
				{ color: 'light-blue darken-3', icon: 'event_repeat',  			title: 'horário fixo', 	text: 'Para cliente semanal, quinzenal ou mensal e eventos diários como <b>almoço e etc...</b>' },
			],

			features: [
				{ icon: 'shopping_cart', text: 'Cadastre seus <b>produtos</b> e tenha um controle de <b>estoque</b> para suas <b>vendas</b>'},
				{ icon: 'receipt_long', text: 'Ofereça <b>pacotes</b> de serviços com preços especiais e <b>fidelize</b> clientes'},
				{ icon: 'photo_camera', text: '<b>Personalize</b> sua página enviando <b>fotos</b> do seu local de trabalho, equipamentos e muito mais'},
			],
			
			faqs: [
				{
					question: 'Serei cobrado pelos serviços que presto ou pelos produtos que vendo aos meus clientes?',
					answer: 'Você pode cadastrar quantos produtos ou serviços quiser e vendê-los livremente sem taxas adicionais.'
				},
				{
					question: 'Posso ter mais de um profissional com agendas separadas entre eles?',
					answer: 'Sim, para isso basta cadastrar um novo funcionário e uma nova agenda independente será criada para ele.'
				},
				{
					question: 'Como funciona o pagamento?',
					answer: 'O pagamento é feito por cartão de crédito, cobrado mensalmente a cada 30 dias, podendo ser cancelado a qualqer momento'
				},
				{
					question: 'Como eu posso divulgar a minha agenda?',
					answer: 'Você pode colocar o link da sua agenda na sua página do Instagram, Facebook ou Whatsapp, sua empresa aparecera no Google automaticamente'
				},
				{
					question: 'Meus dados estão seguros?',
					answer: 'Sim, além de nossos sistemas contarem com criptografia de ponta nós seguimos as normas da LGPD (Lei Geral de Proteção de Dados Pessoais)'
				},
				{
					question: 'Existe alguma taxa além da mensalidade?',
					answer: 'Não, não existem tarifas surpresas ou custos adicionais de nenhum tipo.'
				},
				{
					question: 'Como funciona o período de testes?',
					answer: 'Após o cadastro, nós esperaremos 30 dias para fazer a primeira cobrança, você pode cancelar sua conta a qualquer momento durante esse primeiro mês que nenhum valor será descontado.'
				},
				{
					question: 'Como eu abro uma solicitação de suporte?',
					answer: 'Na área administrativa, no menu lateral, acesse a página de ajuda, depois basta clicar em solicitar suporte no botão inferior da tela.'
				}
			]
		}
  	}
}
</script>

<style lang="scss">
.collored-spanner {
	max-width: 120px;
	width: 25vw;
	height: 3px;
}

.design-banner {
	min-height: 480px;
	background-size: auto;
	background-repeat: no-repeat;
	background-position: 0 0, 100% 0;
	background-image: url('../assets/banner/top.png'), url('../assets/banner/right.png') ;
}

.scroll-instructions {
	width: fit-content;

	> p {
		width: fit-content;
	}

	.scroll-ico {
		display: block;
		margin: 0 auto;
		width: fit-content;
	}
}

.beneficios-wrapper {
	background-color: #f57c00;
	background-image: radial-gradient(ellipse at top, #f8bf15, transparent), radial-gradient(ellipse at bottom, #fe9522, transparent);

	@media (min-width: 600px) {
		border-radius: 8px;
	}
}

.app-banner {
	background-size: auto;
	background-repeat: no-repeat;
	background-color: rgba(255, 255, 255, 0.8);
	background-position: 95% 50%, 5% 85%, 55% 35%, 50% 5%, 75% 95%, 10% 5%;
	background-image: url('../assets/banner/e1.png'), url('../assets/banner/e2.png'), url('../assets/banner/e3.png'), url('../assets/banner/e4.png'), url('../assets/banner/e5.png'), url('../assets/banner/e6.png');
}

.feature-card  {
	position: relative;
	&::before {
		content: " ";
		display: block;
		top: 15%;
		left: -28px;
		background-color: #f794094d;
		position: absolute;
		width: 28px;
		height: 70%;
		border-radius: 12px 0 0 12px;
	}

	&::after {
		content: " ";
		display: block;
		top: 15%;
		right: -28px;
		background-color: #f794094d;
		position: absolute;
		width: 28px;
		height: 70%;
		border-radius: 0 12px 12px 0;
	}
}

.beneficios-text {
	margin-top: 6px;
    text-align: justify;
    white-space: break-spaces !important;
}

.v-expansion-panel-content { 
	flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.center-slider .v-slide-group__content{justify-content: center;}
</style>
