<template>
	<v-app>
		<v-navigation-drawer app temporary v-model="drawer">
			<v-list-item class="my-3">
				<v-list-item-avatar width="50" height="50">
					<v-img src="./assets/dark-short-logo.png"  />
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title>App Agendamento</v-list-item-title>
					<v-list-item-subtitle>Simples, Rápido e Fácil</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>
			<v-list dense>
				<v-list-item @click="$logEvent('drawer_home_clicked')" to="/" link>
					<v-list-item-icon>
						<v-icon>home</v-icon>
					</v-list-item-icon>
					
					<v-list-item-content>
						<v-list-item-title>Início</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			
				<v-list-item @click="$logEvent('drawer_login_clicked')" href="https://configuracoes.appagendamento.com/" target="_blank" link>
					<v-list-item-icon>
						<v-icon>login</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Entrar</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				
				<v-list-item @click="$logEvent('drawer_lead_clicked')" to="/criar-conta" link>
					<v-list-item-icon>
						<v-icon>app_registration</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Criar Conta</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			
				<v-list-item link v-for="(item, i) in links" :to="item.path" :key="`links-${i}`" @click="$logEvent(`drawer_${item.eventName}`)">
					<v-list-item-icon>
						<v-icon v-text="item.icon" />
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title v-text="item.text" />
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>

		<v-app-bar app color="black">
			<v-toolbar-title>
				<router-link to="/" aria-label="Voltar para página inicial" title="Voltar para página inícial">
					<v-img src="./assets/light-long-logo.png" @click="$logEvent('logo_clicked')" contain position="left" alt="Logotipo" max-height="48" />
				</router-link>
			</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-btn icon @click="drawer = true; $logEvent('menu_clicked')" color="white" class="d-flex d-sm-none">
				<v-icon>menu</v-icon>
			</v-btn>

			<v-btn plain @click="$logEvent('header_about_clicked')" to="/sobre" color="white" class="d-none d-sm-flex">Sobre Nós</v-btn>
			<v-btn plain @click="$logEvent('header_contact_clicked')" to="/contato" color="white" class="d-none d-sm-flex">Fale Conosco</v-btn>
			<v-btn plain @click="$logEvent('header_lead_clicked')" to="/criar-conta" color="white" class="d-none d-sm-flex">Criar Conta</v-btn>
			<v-btn plain @click="$logEvent('header_login_clicked')" href="https://configuracoes.appagendamento.com/" target="_blank" color="white" class="d-none d-sm-flex">Entrar</v-btn>
		</v-app-bar>

		<v-main>
			<transition name="scroll-y-transition">
				<router-view @handle="handle" ref="page" />
			</transition>
		</v-main>

		<v-container fluid style="z-index: 1;">
			<v-row no-glutters class="orange darken-2 align-center">
				<v-col class="text-left white--text" cols="8">
					<strong class="subheading">Siga a gente nas redes sociais!</strong>
				</v-col>

				<v-col class="text-right" cols="4">
					<v-btn dark icon v-for="(i, j) in socials" :key="`socials-${j}`" :href="i.link" :title="i.desc" @click="$logEvent(i.eventName)" target="_blank" color="white" class="my-2">
						<svg v-if="i.icon == 'facebook'" style="width:24px;height:24px" viewBox="0 0 24 24">
							<path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
						</svg>

						<svg v-if="i.icon == 'instagram'" style="width:24px;height:24px" viewBox="0 0 24 24">
							<path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
						</svg>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<v-footer dark padless>
			<v-row justify="center" no-gutters>
				<v-btn text rounded v-for="(link, i) in links" :to="link.path" :key="i" color="white" class="my-2" @click="$logEvent(`footer_${link.eventName}`)">
					{{link.text}}
				</v-btn>
			
				<v-col class="py-4 text-center white--text" cols="12">
					&copy; {{new Date().getFullYear()}}
					<a class="no-link" target="_blank" href="https://mazzcorp.net/" rel="noreferrer" @click="$logEvent('mazzcorp_clicked')"> Mazzcorp </a> | Todos os direitos reservados.
				</v-col>
			</v-row>
		</v-footer>

		<!-- remover 'false &&' somente quando for obrigatorio (por lei) exibir essa modal
		<div v-show="false && !hideCookiesDialog" id="cookies-banner">
			<p>Nós utilizamos cookies para que você possa ter uma melhor experiência nesse site.</p>
			<v-btn rounded dark color="black" @click="acceptCookies()">ok, eu aceito</v-btn>
		</div>
		 -->

		<vue-recaptcha @verify="verify" ref="recaptcha" sitekey="6LciAwMbAAAAAD_bx8PfEkAaLuiRy1zxN7dvm0Nz" size="invisible" badge="inline"></vue-recaptcha>
		<v-btn fab fixed bottom right dark v-if="wpp" color="#25D366" href="https://wa.me/5516988275392" target="_blank" @click="$logEvent('wpp_clicked')">
			<v-icon dark size="32">whatsapp</v-icon>
		</v-btn>
	</v-app>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
export default {
	name: 'App',
	components: { VueRecaptcha },

	methods: {
		handle(event) {
			switch (event) {
				case 'captcha-check':
					this.$refs.recaptcha.execute()
					break;

				case 'hide-wpp':
					this.wpp = false;
					break;

				case 'show-wpp':
					this.wpp = true;
					break;
			
				default:
					break;
			}
		},

		verify(token) {
			const handle = this.$refs.page.onTokenCreated
			if(handle) handle(token)
		},

		acceptCookies() {
			this.hideCookiesDialog = true
			localStorage.setItem('cookiesAcceptedAt', new Date().toJSON())
		}
	},

	created() {
		this.hideCookiesDialog = !!(localStorage.getItem('cookiesAcceptedAt'))
	},

	data() {
		return {
			hideCookiesDialog: false,
			drawer: false, wpp: true,

			socials:[
				{ icon: 'instagram', link: 'https://www.instagram.com/app_agendamento/', desc: 'Siga a gente no Instagram', eventName: 'insta_clicked' },
				{ icon: 'facebook', link: 'https://www.facebook.com/app.agendamento', desc: 'Curta a gente no Facebook', eventName: 'fb_clicked' },
			],

			links: [
				{ icon: 'help', text: 'Quem Somos', path: '/sobre', eventName: 'about_clicked' },
				{ icon: 'question_answer', text: 'Fale Conosco', path: '/contato', eventName: 'contact_clicked' },
				{ icon: 'menu_book', text: 'Termos de Uso', path: '/termos-de-uso', eventName: 'terms_clicked' },
				{ icon: 'policy', text: 'Política de Privacidade', path: '/politica-de-privacidade', eventName: 'privacy_clicked' },
			]
		}
	},
};
</script>

<style lang="scss">
.grecaptcha-badge {
	position: absolute;
	left: -260px;
}

.no-link {
  font-weight: bold;
  text-decoration: none;
  color: inherit !important;
}

.shadow--text{
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .7);
}

#cookies-banner {
  font-size: 14px;
  width: 260px;
  padding: 20px;
  box-shadow: 0 0 2px black;
  border-radius: 4px;
  text-align: center;
  background-color: white;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 999;
}

.v-toolbar__title{min-width: 50px;}
</style>
