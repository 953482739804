import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		name: 'about',
		path: '/sobre',
		component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
	},
	{
		name: 'cancelled',
		path: '/pagamento-invalido',
		component: () => import(/* webpackChunkName: "cancelled" */ '../views/cancelled.vue')
	},
	{
		name: 'contact',
		path: '/contato',
		component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
	},
	{
		name: 'create',
		path: '/criar-conta',
		component: () => import(/* webpackChunkName: "create" */ '../views/create-account.vue')
	},
	{
		name: 'created',
		path: '/conta-criada',
		component: () => import(/* webpackChunkName: "created" */ '../views/account-created.vue')
	},
	{
		name: 'usage-terms',
		path: '/termos-de-uso',
		component: () => import(/* webpackChunkName: "usage-terms" */ '../views/terms.vue')
	},
	{
		name: 'privacy-police',
		path: '/politica-de-privacidade',
		component: () => import(/* webpackChunkName: "privacy-police" */ '../views/privacy.vue')
	}
]

Vue.use(VueRouter)
export default new VueRouter({ routes })
