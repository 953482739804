import Vue from 'vue';
import pt from 'vuetify/lib/locale/pt';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
export default new Vuetify({
	theme: {
		themes: {
            light: {
                primary: colors.orange.darken2,
                secondary: colors.indigo.base,
                accent: colors.pink.base
            }
        }
	},
	lang: {
		locales: { pt },
		current: 'pt',
	},
	icons: {
		iconfont: 'md',
	},
});
