import Vue from 'vue'
import App from './App.vue'
import typed from 'vue-typed-js'
import router from './services/router'
import vuetify from './services/vuetify'
import { VueMaskDirective } from 'v-mask'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from "firebase/analytics";

const app = initializeApp({
  apiKey: "AIzaSyBaNzqsCMFn6cOhD59ZryanS06riVfmaVc",
  authDomain: "appagendamento.com",
  databaseURL: "https://barberine-ce6ea-default-rtdb.firebaseio.com",
  projectId: "barberine-ce6ea",
  storageBucket: "barberine-ce6ea.appspot.com",
  messagingSenderId: "1030804390546",
  measurementId: "G-4P8TPSC1MK",
  appId: "1:1030804390546:web:a7515a491be151083b1c4b"
})

const analytics = getAnalytics(app);
const logger = function(name) {
  if(process.env.NODE_ENV == 'production') {
    logEvent(analytics, name)
  }
  else {
    console.log({name})
  }
}

Vue.use(typed)
Vue.config.productionTip = false
Vue.prototype.$logEvent = logger
Vue.directive('mask', VueMaskDirective)

const render = function (h) { return h(App) }
new Vue({ router, vuetify, render }).$mount('#app')
